import axios from "./http";
import qs from "querystring";
import axiosJson from "./httpJson"

const request = {
  async get(url, data) {
    try {
      const response = await axios.get(url, {params: data});
      const {data: res} = response;
      return new Promise((resolve) => {
        const {code = -1} = res;
        if (code === 0) {
          resolve(res);
        } else {
          resolve(res);
        }
      }).catch(err => {
        console.log(err);
      });
    } catch (err) {
      console.log(err);
    }
  },
  async post(url, data) {
    try {
      const response = await axios.post(url, qs.stringify(data));
      const {data: res} = response;
      return new Promise((resolve, reject) => {
        const {code = -1} = res;
        if (code === 0) {
          console.log("resolve =======================");
          resolve(res);
        } else {
          console.log("reject =======================");
          resolve(res);
        }
      }).catch(err => {
        console.log("Promise catch =======================");
        console.log(err);
      });
    } catch (err) {
      console.log("try catch =======================");
      console.log(err);
    }
  },


  async postJson(url, data) {
    try {
      const response = await axiosJson.post(url,data);
      const {data: res} = response;
      return new Promise((resolve, reject) => {
        const {code = -1} = response;
        if (code === 0) {
          console.log("resolve =======================");
          resolve(res);
        } else {
          console.log("reject =======================");
          resolve(res);
        }
      }).catch(err => {
        console.log("Promise catch =======================");
        console.log(err);
      });
    } catch (err) {
      console.log("try catch =======================");
      console.log(err);
    }
  }
};
export default request;
