import request from "@/axios";
// let url = "http://eval.etuic.com/eval-api";
let url = "https://xcx.etuic.com/eval-api";
// let url = "http://192.168.2.98:8090/eval-api"

export default {
  //获取设备基础信息
  getDeviceByMacAddr (params) {
    return request.get(url + "/open/nqueue/device/getByMac?macAddr=" + params);
  },
  getRandomMac () {
    return request.get(url + "/open/nqueue/getRandomMac");
  },
  //登录
  login (params) {
    return request.postJson(url + "/open/nqueue/eval/login", params);
  },
  //退出登录
  logout (params) {
    return request.postJson(url + "/nqueue/eval/logout/" + params);
  },
  //根据token获得用户信息
  getUserInfo (params) {
    return request.post(url + "/nqueue/eval/worker/info", params);
  },
  //叫号器 - 代办列表
  getCallList (params) {
    return request.get(url + "/nqueue/call/todo?callMacAddr=" + params);
  },
  //叫号器 - 已办列表
  getCallListFinish (params) {
    return request.get(url + "/nqueue/call/finish?callMacAddr=" + params);
  },
  //叫号器 - 叫号
  callTicket (params) {
    return request.get(url + "/nqueue/call/callTicket?callMacAddr=" + params);
  },
  //叫号器 - 评价
  onEval (params) {
    return request.get(url + "/nqueue/call/appraise?callMacAddr=" + params);
  },
  //叫号器 - 跳过此号
  skipTicket (params) {
    return request.get(url + "/nqueue/call/skipTicket?callMacAddr=" + params);
  },
  //叫号器 - 暂停服务
  pauseService (params) {
    return request.get(url + "/nqueue/call/pause?callMacAddr=" + params);
  },
  //叫号器 - 欢迎光临
  welcome (params) {
    return request.get(url + "/nqueue/call/welcome?callMacAddr=" + params);
  },
  //叫号器 - 重复呼叫
  repeatCall (params) {
    return request.get(url + "/nqueue/call/repeatCall?callMacAddr=" + params);
  },
  //叫号器 - 当前正在办理的业务
  getInBusinessRecord (params) {
    return request.get(url + "/nqueue/call/getInBusinessRecord?callMacAddr=" + params);
  },
  //叫号器 - 设备密码校验
  validatePassword (params1, params2) {
    return request.get(url + "/open/nqueue/device/validatePassword?macAddr=" + params1 + '&setPassword=' + params2);
  },
  //排队屏-列表
  getQueueScreenList (params) {
    return request.get(url + "/open/nqueue/call/todo/" + params);
  },
  //查询预约业务列表
  listSubscribe (params) {
    return request.get(url + "/nqueue/subscribe/list?" + params);
  },
  //业务状态
  subscribeStatus (params) {
    return request.get(url + "/system/dict/data/type/subscribe_status", params);
  },
  //查询预约业务详细
  getSubscribe (id) {
    return request.get(url + '/nqueue/subscribe/' + id);
  },
  //新增预约业务
  addSubscribe (params) {
    return request.post(url + '/nqueue/subscribe', params);
  },
  // 查询业务事项类型列表
  listHcpdb (params) {
    return request.get(url + '/eval/hcpdb/list', params);
  },
  // 查询上报查询详细
  getHcp (hcpId) {
    return request.get(url + '/eval/hcp/' + hcpId);
  },
  // 新增上报查询
  addHcp (params) {
    return request.postJson(url + '/eval/hcp', params);
  },
  getUnitList (params) {
    return request.get('https://www.etuic.com/prod-api/system/unituser/listByNetCode', params);
  },
  // 查询部门下拉树结构
  treeselect () {
    return request.get(url + '/system/dept/treeselect');
  },
  getUploadOrder () {
    return request.get(url + '/system/config/list?pageNum=1&pageSize=10&configKey=upload_order');
  },
  //设置业务类型
  updateType (params) {
    return request.post(url + "/queue/busi/updateType?id=" + params.id + '&type=' + params.type);
  },
}
