import Vue from "vue";
import VueRouter from "vue-router";
import DeviceConnect from "../views/DeviceConnect.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "DeviceConnect",
    component: DeviceConnect,
  },
  //登录
  {
    path: "/login",
    name: "login",
    meta: { showNav: true },
    component: () =>
      import("../views/login.vue"),
  },
  //登录
  {
    path: "/a/:id",
    name: "LoginNew",
    meta: { showNav: true },
    component: () =>
      import("../views/LoginNew.vue"),
  },

  //叫号
  {
    path: "/CallNumber",
    name: "CallNumber",
    meta: { showNav: true },
    component: () =>
      import("../views/CallNumber.vue"),
  },
  {
    path: "/AboutInfo",
    name: "AboutInfo",
    meta: { showNav: true },
    component: () =>
      import("../views/AboutInfo.vue"),
  },
  {
    path: "/LineUp",
    name: 'LineUp',
    component: () => import('../views/LineUp.vue'),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


// router.beforeEach((to, from, next) => {
//     sessionStorage.setItem("showNav", !!to.matched.some(item => item?.meta?.showNav))
//     next();
// })

export default router;
