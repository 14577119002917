import axios from "axios";
import { getToken } from '../utils/auth'

// axios 配置
axios.defaults.timeout = 6000;
axios.defaults.baseURL = "";

axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";

// http request 拦截器
axios.interceptors.request.use(async (config = {}) => {

  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config;
},
  (error) => {
    return Promise.reject(error);
  }
);

// http response 拦截器
axios.interceptors.response.use(async (res) => {
  return res;
},
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
