import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const UserInfo = "UserInfo"
const userId = "userId"
const username = "username"
const realName = "realName"
const starLevel = "starLevel"
const organName = "organName"
const deptId = "deptId"
const deptName = "deptName"
const headImg = "headImg"
const videoSrc = "videoSrc"


export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}
//用户信息
export function getUserInfo () {
  return Cookies.get(UserInfo)
}
export function setUserInfo (userinfo) {
  return Cookies.set(UserInfo,JSON.stringify(userinfo) )
}
