<template>
	<div :style="background" class="device-connect">
		<div class="header">
			<img
				class="logo"
				@click="openAbout"
				src="../assets/image/government_icon.jpg"
			/>
			<div class="title">
				<p class="zh">河北省石家庄市桥西区政务服务中心</p>
				<p
					class="en"
				>SHIJIAZHUANG QIAOXI DISTRICT CITZEN SERVICE CENTER</p>
			</div>
		</div>
		<div class="qrcode" ref="qrCodeUrl"></div>
		<div class="item">{{macAddr}}</div>

		<div></div>
		<div class="bottom">
			<span class="sure" @click="clickSure">确认</span>
		</div>
	</div>
</template>
<script>
import api from "../api/index";
import QRCode from 'qrcodejs2'
export default {

	data () {
		return {
			background: {
				backgroundColor: '#5A9AD1',
				// 背景图片地址
				backgroundImage: 'url(' + require('../assets/image/lineup_comm_bg.png') + ')',
				// 背景图片是否重复
				backgroundRepeat: 'no-repeat',
				// 背景图片大小
				backgroundSize: '100% auto',
				// 背景图片位置
				backgroundPosition: '0 0'

			},
			resetScoket: true,
			socket: null,
			macAddr: '',
			showTip: '正在连接，请稍后......',
			refresh: false,
			timer: null,
		}
	},
	mounted () {
    this.macAddr = this.$route.params.id
		this.init();
	},
	methods: {
		//跳转到关于页面
		openAbout () {
			this.$router.push({
				name: 'AboutInfo',
			})
		},
		async init () {
			this.refresh = this.$route.query.refresh
			let macAddr = localStorage.getItem("macAddr")
			if (macAddr) {
				this.macAddr = macAddr
			} else {
				await this.getMacAddr()
			}
			this.creatQrCode()
			await this.getDeviceByMacAddr();
			this.initWebSocket()
		},
		creatQrCode () {
			var qrcode = new QRCode(this.$refs.qrCodeUrl, {
				text: this.macAddr, // 需要转换为二维码的内容
				width: 180,
				height: 180,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.H
			})
		},
		clickSure () {
			this.getDeviceByMacAddr()
		},
		// 获取mac地址
		getMacAddr: async function () {
			const res = await api.getRandomMac();
			this.macAddr = res?.msg ?? ''
			localStorage.setItem("macAddr", this.macAddr)
		},
		// 获取设备基础信息 1取号机2叫号器3窗口屏4排队屏5评价器
		getDeviceByMacAddr: async function () {
			const res = await api.getDeviceByMacAddr(this.macAddr);
			if (res.data) {
				if (res.data.deviceType === 2) {//叫号器
					localStorage.setItem("macAddr", this.macAddr)
					this.toLogin()
				} else if (res.data.deviceType === 4) {//排队屏
					localStorage.setItem("macAddr", this.macAddr)
					this.toLineUp()
				}
				sessionStorage.setItem("ledServiceAddress", res.data.ledServiceAddress)
			} else {
				this.$toast({
					message: '当前mac地址不存',
					position: 'middle'

				});
			}

		},
		toLogin () {
			this.$router.replace({
				name: 'login'
			})
		},
		toLineUp () {
			this.$router.replace({
				name: 'LineUp',
			})
		},

		initWebSocket () {
			// this.socket = new WebSocket("ws://8.142.65.123:8090/eval-api/ws/queue/" + this.macAddr)
			this.socket = new WebSocket("wss://xcx.etuic.com/eval-api/ws/queue/" + this.macAddr)

			this.socket.onopen = this.socketOnOpen
			this.socket.onmessage = this.socketOnMessage;
			this.socket.onclose = this.socketOnClose;
			// this.socket.onerror = this.socketOnError;
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
			this.timer = setInterval(() => {
				this.socket?.send(this.macAddr);
			}, 180000)
		},
		socketOnOpen () {
			console.log("连接建立成功");
		},
		socketOnMessage (res) {
			console.log("后台传递数据111111", res);
			if (/^客户端/.test(res.data) || /^设备/.test(res.data)) {
				return
			}
			let json = JSON.parse(res?.data ?? '{}')
			let code = json.code;
			if (code === 101) {
				this.getDeviceByMacAddr()
			}


		},
		socketOnClose (e) {
			console.log(e)
			// setTimeout(() => {
			// 	if (this.resetScoket) {
			// 		this.initWebSocket();
			// 	}
			// }, 3000)
			if (this.resetScoket) {
				this.initWebSocket();
			}

		},
		socketSendMessgae (mes) {
			let message = JSON.stringify(mes);
			this.socket?.send(message);
		},
		// socketOnError (e) {
		//   console.log(e)
		// 	setTimeout(() => {
		// 		if (this.resetScoket) {
		// 			this.initWebSocket()
		// 		}
		// 	}, 3000)
		// }
	},
	destroyed () {
		this.resetScoket = false;
		this.socket?.close();
		clearInterval(this.timer)
		this.timer = null
	},
}
</script>
<style scoped lang="scss">
.device-connect {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding-top: 60px;
		box-sizing: border-box;
		.logo {
			width: 110px;
			height: 110px;
		}
		.title {
			padding-left: 35px;
			.zh {
				color: #fff;
				font-size: 40px;
			}
			.en {
				color: #fff;
				font-size: 20px;
			}
		}
	}
	.wait {
		color: #fff;
		display: flex;
		margin-top: 1.2rem;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.qrcode {
		margin-top:60px;
	}
	.item {
		color: #fff;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 10px;
		justify-content: center;
		font-size: 0.9rem;
		.right {
			margin-left: 10px;
			color: #000;
		}
	}
	.bottom {
		margin-top: 60px;
		display: flex;
		font-size: 25px;
		color: #fff;
		box-sizing: border-box;

		.sure {
			background-color: #84c7ff;
			border-radius: 5px;
			width: 230px;
			height: 60px;
			line-height: 60px;
			text-align: center;
		}
	}
	.mac {
		flex: 0 0 3.04rem;
		color: #fff;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
</style>
