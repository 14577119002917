<template>
	<div id="app">
		 <nav>
      <router-link to="/"></router-link>
      <!-- <router-link to="/about">About</router-link> -->
    </nav>
    <router-view />
	</div>
</template>

<script>
export default {

	data () {
		return {
			// background: {
			// 	backgroundColor: '#017AEC',
			// 	// 背景图片地址
			// 	backgroundImage: 'url(' + require('./assets/images/article_bg.jpg') + ')',
			// 	// 背景图片是否重复
			// 	backgroundRepeat: 'no-repeat',
			// 	// 背景图片大小
			// 	backgroundSize: '100% auto',
			// 	// 背景图片位置
			// 	backgroundPosition: '0 0'

			// }
		};
	},
	mounted () {

		this.initRem();
	},
	methods: {
		// showTab () {
		// 	var name = this.$route.path
		// 	if (name === '/home' || name === '/type' || name === '/article' || name === '/PolicyDocument') {
		// 		return false
		// 	} else {
		// 		return true
		// 	}

		// },
		initRem () {
			var docEl = document.documentElement
			var dpr = window.devicePixelRatio || 1

			// adjust body font size
			function setBodyFontSize () {
				if (document.body) {
					document.body.style.fontSize = (12 * dpr) + 'px'
				}
				else {
					document.addEventListener('DOMContentLoaded', setBodyFontSize)
				}
			}
			setBodyFontSize();

			// set 1rem = viewWidth / 10
			function setRemUnit () {
				var rem = docEl.clientWidth / 20
				docEl.style.fontSize = rem + 'px'
			}

			setRemUnit()

			// reset rem unit on page resize
			window.addEventListener('resize', setRemUnit)
			window.addEventListener('pageshow', function (e) {
				if (e.persisted) {
					setRemUnit()
				}
			})

			// detect 0.5px supports
			if (dpr >= 2) {
				var fakeBody = document.createElement('body')
				var testElement = document.createElement('div')
				testElement.style.border = '.5px solid transparent'
				fakeBody.appendChild(testElement)
				docEl.appendChild(fakeBody)
				if (testElement.offsetHeight === 1) {
					docEl.classList.add('hairlines')
				}
				docEl.removeChild(fakeBody)
			}

			var viewport = document.querySelector("meta[name=viewport]");
			dpr = window.devicePixelRatio || 1;
			var scale = 1 / dpr;
			scale = 1
			//下面是根据设备dpr设置viewport
			viewport.setAttribute(
				"content",
				"width=device-width,initial-scale=" +
				scale +
				", maximum-scale=" +
				scale +
				", minimum-scale=" +
				scale +
				", user-scalable=no"
			);
		}
	}
}
</script>


<style lang="scss">
html {
	height: 100%;
}
body,
p,
ul,
ol,
li {
	margin: 0;
	padding: 0;
}
ul,
ol,
li {
	list-style: none;
}
body {
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 14px;
	color: #000;
	font-family: "Micdoft YaHei";
}
#app {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>
