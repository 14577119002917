import axios from "axios";
import { getToken } from '../utils/auth'

// axios 配置
axios.defaults.timeout = 6000;
axios.defaults.baseURL = "";
axios.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";


// http request 拦截器
axios.interceptors.request.use(async (config = {}) => {
  // config.headers['Authorization'] = 'Bearer ' + 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjdiMDI3MmY1LTVkNTItNGQ2NC1hYmJhLTZiYWUxZmJmZDM3MCJ9.cMNHyVsQr3_63iGcl8NO00HHfNErJKAs4qHkTKKnVaPwYTlEVnIrBy3dG5CQUJM6K-k4PgrrvuPWMTrPmfGD1g'

  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  // config.headers['Authorization'] = 'Bearer ' + getToken
  return config;
},
  (error) => {
    return Promise.reject(error);
  }
);

// http response 拦截器
axios.interceptors.response.use(async (res) => {
  return res;
},
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
